<template>
  <div class="admin-wrapper grid">
    <h1 class="project__header">{{ project.delivery.address }}</h1>
    <span class="project__delete" @click="onDelete()">Slet projekt</span>
    <div class="project__top">
      <div class="project__top-info">
        <label>Kunde</label>
        <span class="project__name">{{ project.delivery.company }}</span>
      </div>
      <div class="project__top-info">
        <label>Ønsket dato</label>
        <span>{{ formatDate(project.delivery.installationDate) }}</span>
      </div>
      <template v-if="project.status == 'requested'">
        <span class="project__action" :class="{ 'project__action--disabled': isSaving }" @click="onReject()">Afvis</span>
        <button class="button button--action button--small" @click="onAccept()" :disabled="isSaving">Accepter</button>
      </template>
      <span v-if="project.status == 'rejected'">Afvist</span>
      <div class="project__status-container" v-if="project.status == 'accepted'">
        <!-- prettier-ignore -->
        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#bc9183"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
        <span class="project__status--accepted">Accepteret</span>
      </div>
    </div>

    <div>
      <div class="projects__step">
        <h2 class="projects__subheader">Opstart</h2>
        <span class="project__label">Estimeret pris eks. moms</span>
        <span class="project__price">{{ getFirstPhasePrice }} DKK</span>
      </div>
      <template v-for="step in selectedOptionalSteps">
        <div
          @click="onStepSelect(step, instance)"
          class="projects__step projects__step--clickable"
          :class="{ 'projects__step--active': stepFilter == step.slug && instance == stepInstance }"
          v-for="instance in step.instances"
          :key="`${step.slug}-${instance}`"
        >
          <h2 class="projects__subheader">{{ optionalStepNameBySlug(step.slug) }} ({{ instance }})</h2>
          <span class="project__label">Estimeret pris eks. moms</span>
          <span class="project__price">{{ getPrice(step.slug, instance) }} DKK</span>
        </div>
      </template>
      <div>
        <div class="project__image-container" v-for="image in project.images" :key="image.url">
          <img class="project__image" :src="image.url" />
        </div>
      </div>
    </div>
    <div class="project__items" v-if="stepFilter !== ''">
      <template v-for="subStep in filteredSubSteps">
        <div v-if="stepFilter == subStep.parent" :key="subStep.id">
          <h3 class="project__substep">{{ subStep.name }}</h3>
          <template v-for="(item, index) in filteredItems">
            <div class="project__item" v-if="subStep.id == item.subStep && stepInstance == item.instance" :key="`${subStep.id}-${index}`">
              <label>{{ item.name }}</label>
              <span>{{ item.amount }} {{ item.unit }}</span>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DateTime from 'luxon/src/datetime.js'

export default {
  name: 'admin-project',
  data() {
    return {
      stepFilter: '',
      stepInstance: '',
      isSaving: false,
    }
  },
  async created() {
    await this.getProject(this.$route.params.id)
    if (!this.subSteps) await this.getSubSteps()
    await this.getOptionalSteps()
    this.stepFilter = this.selectedOptionalSteps[0].slug
  },
  methods: {
    ...mapActions(['getProject', 'getSubSteps', 'getOptionalSteps', 'updateProject', 'deleteProject']),
    onStepSelect(step, instance) {
      this.stepFilter = step.slug
      this.stepInstance = instance
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })
    },
    getSubStepNameById(subStepId) {
      return this.subSteps.find(subStep => subStep.id == subStepId)
    },
    async onReject() {
      // Condition to not allow multiple updates
      if (this.isSaving == false) {
        this.isSaving = true
        this.project.status = 'rejected'
        await this.updateProject(this.project)
        this.isSaving = false
      }
    },
    async onAccept() {
      this.isSaving = true
      this.project.status = 'accepted'
      await this.updateProject(this.project)
      this.isSaving = false
    },
    async onDelete() {
      this.$confirm({
        message: `Er du sikker på at du vil slette dette project?`,
        button: {
          yes: 'Ja',
          no: 'Nej',
        },
        callback: async confirm => {
          if (confirm) {
            await this.deleteProject(this.project.id).catch(() => this.deleteError())
            this.$router.push({ name: 'admin-projects', params: { userId: '123' } })

            this.deleteSucces()
          }
        },
      })
    },
    deleteSucces() {
      this.$notify({
        type: 'success',
        title: 'Succes!',
        text:
          'Projektet er slettet <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="#fff"/></svg>',
      })
    },
  },
  computed: {
    ...mapGetters(['project', 'requiredSteps', 'optionalStepNameBySlug', 'getPrice', 'getFirstPhasePrice', 'subSteps']),
    selectedOptionalSteps() {
      const requiredStepsSlugs = this.requiredSteps.map(requiredStep => requiredStep.slug)
      return this.project.steps.filter(step => !requiredStepsSlugs.includes(step.slug))
    },
    filteredItems() {
      const itemsWithAmount = this.project.items.filter(item => item.amount !== '')
      return itemsWithAmount
    },
    filteredSubSteps() {
      let currentSubStepsArray = this.filteredItems.map(item => this.getSubStepNameById(item.subStep))
      return [...new Set(currentSubStepsArray)]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/shared-styles/button.scss';
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.project__top {
  grid-column: 1 / -1;
  display: grid;
  align-items: center;
  padding: 1.5rem 1.25rem;
  background: #faf9f9;
  grid-template-columns: auto 1fr auto auto;
  gap: 1.5rem;
}
.project__top-info {
  display: grid;
  height: 100%;
  margin-right: 2.5rem;
  font-size: 0.935rem;
  & .project__name {
    font-weight: 700;
    color: #474746;
    margin-top: 0.25rem;
  }
}
.project__delete {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: #333;
  }
}
.project__action {
  color: #474746;
  cursor: pointer;
  &:hover {
    color: #333;
  }
}
.project__action--disabled {
  opacity: 0.5;
  cursor: wait;
}
.project__status-container {
  display: flex;
  align-items: center;
}
.project__status--accepted {
  color: var(--color-cta);
  margin-left: 0.5rem;
}
.projects__subheader {
  grid-column: 1 / -1;
  color: #474746;
  font-size: 1rem;
  margin-top: 0;
}
.projects__step {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem 1rem;
  background-color: #faf9f9;
  padding: 1.5rem 1.25rem;
  margin-bottom: 1.5rem;
}
.projects__step--clickable {
  cursor: pointer;
  &:hover {
    background-color: #f7f4f3;
  }
}
.projects__step--active {
  background-color: #f7f4f3;
  box-shadow: inset 4px 0 0 0 var(--color-cta);
}
.project__label {
  color: #474746;
}
.project__price {
  color: #474746;
  font-weight: 700;
  text-align: right;
}
.project__image-container {
  position: relative;
  display: flex;
  background-color: #faf9f9;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  width: 175px;
  height: 175px;
  padding: 0.5rem;
}
.project__image {
  width: 100%;
}
.project__items {
  display: flex;
  flex-direction: column;
  background-color: #faf9f9;
  padding: 1.5rem 1.25rem;
  gap: 2rem;
}
.project__substep {
  color: #474746;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}
.project__item {
  display: flex;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e8e7e7;
  & span {
    margin-left: auto;
    color: #474746;
  }
}
</style>
